import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import type { GeoLocation } from "@src/appV2/Location/types";
import { Virtuoso } from "react-virtuoso";

import type { MapViewWorkplace } from "../MapView/types";
import { WorkplacesListEmptySearchResult } from "./EmptySearchResult";
import { WorkplacesListEmptyState } from "./EmptyState";
import { WorkplaceListItem } from "./Item";
import { WorkplacesListLoadingState } from "./LoadingState";
import { WorkplaceListItemPlaceholder } from "./Placeholder";

interface WorkplacesListProps {
  isEmpty: boolean;
  isSearchEmpty: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  scrollRef: React.RefObject<HTMLDivElement>;
  searchTerm: string;
  totalCount: number;
  allWorkplaces: MapViewWorkplace[];
  visibleWorkplaces: MapViewWorkplace[];
  distanceFilter: number;
  geoLocationFilter: GeoLocation;
}

export function WorkplacesList(props: WorkplacesListProps) {
  const {
    isError,
    isLoading,
    isSuccess,
    allWorkplaces,
    visibleWorkplaces,
    scrollRef,
    searchTerm,
    isEmpty,
    isSearchEmpty,
    totalCount,
    distanceFilter,
    geoLocationFilter,
  } = props;

  if (isError) {
    return <Text align="center">Error loading workplaces. Please try again.</Text>;
  }

  if (isLoading) {
    return <WorkplacesListLoadingState />;
  }

  if (isSuccess && isEmpty) {
    return <WorkplacesListEmptyState distanceFilter={distanceFilter} />;
  }

  return (
    <Virtuoso
      customScrollParent={scrollRef.current ?? undefined}
      width="100%"
      data={allWorkplaces}
      increaseViewportBy={100}
      topItemCount={0}
      totalCount={totalCount}
      itemContent={(index) => {
        const item = visibleWorkplaces[index];

        // Displaying the empty search result here to avoid layout shifts
        if (isSuccess && isSearchEmpty && index === 0) {
          return <WorkplacesListEmptySearchResult searchTerm={searchTerm} />;
        }

        // If the item is not defined, it means the item is not a match
        // for the search term, so we should show a placeholder
        // to maintain the bottom sheet height and avoid layout shifts
        if (!isDefined(item)) {
          return <WorkplaceListItemPlaceholder index={index} />;
        }

        return (
          <WorkplaceListItem index={index} workplace={item} geoLocationFilter={geoLocationFilter} />
        );
      }}
    />
  );
}
