import { TextSkeleton } from "@clipboard-health/ui-components";
import { Box, Stack } from "@mui/material";

import { FacilityAvatar } from "../../components/FacilityAvatar";

interface WorkplaceListItemPlaceholderProps {
  index: number;
}

/**
 * Placeholder is used to maintain the bottom sheet height
 * during search to prevent layout shifts
 */
export function WorkplaceListItemPlaceholder(props: WorkplaceListItemPlaceholderProps) {
  const { index } = props;

  return (
    <Box
      sx={(theme) => ({
        opacity: 0,
        pointerEvents: "none",
        ...(index !== 0 && {
          paddingTop: 4,
          borderTop: `1px solid ${String(theme.palette.divider)}`,
        }),
      })}
    >
      <Stack direction="row" spacing={3} alignItems="center" pb={4}>
        <FacilityAvatar size="small" />
        <Stack>
          <TextSkeleton variant="h6" width="15ch" />
          <TextSkeleton variant="caption" width="15ch" />
        </Stack>
      </Stack>
    </Box>
  );
}
