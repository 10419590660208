import { isDefined } from "@clipboard-health/util-ts";
import { useTheme } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import constate from "constate";
import { useState } from "react";
import { generatePath } from "react-router-dom";

interface UseWorkplaceModalsDataProps {
  /**
   * Base path used for navigation when opening/closing workplace modals.
   * This path is used to:
   * 1. Determine where to navigate when closing modals
   * 2. Support rendering the same modals from different root views (map/list)
   */
  baseNavigationPath: string;
}

/**
 * Provides necessary data for workplace modals.
 * When clearing data (passing undefined) with any of returned setters, the change is delayed until after the modal's exit transition completes.
 * This is to prevent rendering underlying modals with missing data.
 *
 * As these modals can render from both map view and list view, to have a uniform way of constructing
 * modal paths, we use the `generateModalPath` function.
 */
function useWorkplaceModalsData(props: UseWorkplaceModalsDataProps) {
  const { baseNavigationPath } = props;

  // TODO(ModalWorkplaceData): replace Facility with ModalWorkplaceData
  const [workplace, setWorkplace] = useState<Facility>();

  const theme = useTheme();

  const transitionDuration = theme.transitions.duration.leavingScreen;

  const handleSetWorkplace = (newWorkplace?: Facility) => {
    if (isDefined(newWorkplace)) {
      setWorkplace(newWorkplace);
      return;
    }

    setTimeout(() => {
      setWorkplace(undefined);
    }, transitionDuration);
  };

  const generateModalPath = <S extends string>(
    path: S,
    params?: Parameters<typeof generatePath<S>>[1]
  ) => {
    return generatePath(
      `${baseNavigationPath}/${path}`,
      params as unknown as Parameters<typeof generatePath<`${string}/${S}`>>[1]
    );
  };

  return {
    baseNavigationPath,
    workplace,
    setWorkplace: handleSetWorkplace,
    generateModalPath,
  };
}

export const [WorkplaceModalsDataProvider, useWorkplaceModalsDataContext] =
  constate(useWorkplaceModalsData);
