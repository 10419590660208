import { matchPath } from "react-router-dom";

import {
  SHIFT_DISCOVERY_ON_CALL_SHIFT_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_CONFIRM_BOOKING_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_DOCUMENT_SUBMITTED_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH,
} from "./paths";
import { type ShiftModalRoutePathParams } from "./types";

export function getShiftModalRouteParams<Params extends ShiftModalRoutePathParams>(
  baseNavigationPath: string,
  locationPathname: string
) {
  const match = matchPath<Params>(locationPathname, {
    path: [
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_ON_CALL_SHIFT_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_CONFIRM_BOOKING_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_DOCUMENT_SUBMITTED_MODAL_PATH}`,
    ],
    exact: true,
    strict: false,
  });

  return match?.params;
}
