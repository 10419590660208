import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

interface WorkplacesListEmptySearchResultProps {
  searchTerm: string;
}

export function WorkplacesListEmptySearchResult(props: WorkplacesListEmptySearchResultProps) {
  const { searchTerm } = props;

  return (
    <Stack alignItems="center" justifyContent="center" pt={3} pb={3}>
      <Text semibold align="center" variant="h6" sx={{ pt: 2, pb: 2 }}>
        No workplaces found for &quot;{searchTerm}&quot;
      </Text>
    </Stack>
  );
}
