import { isDefined } from "@clipboard-health/util-ts";
import type { BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction/types";
import constate from "constate";
import { useEffect, useState } from "react";
import { generatePath, useHistory, useLocation } from "react-router-dom";

import { type ShiftDiscoverySearchMode } from "../../OpenShifts/ShiftAction/api/useClaimShift";
import { type WorkerRequirement } from "../Documents/api/types";
import {
  getBlockingWorkerRequirements,
  hasBlockingWorkerRequirements,
} from "../Documents/utils/blockingRequirementsUtils";
import { type ShiftSlotCoalesced } from "../Shift/Open/useGetShiftsSlotV2";
import { type ModalShiftData } from "../Shift/types";
import { type WorkerPublicProfile } from "../WorkWithFriends/hooks/useGetWorkerPublicProfile";
import { getShiftModalRouteParams } from "./getShiftModalRouteParams";
import { useGetModalShiftData } from "./useGetModalShiftData";

interface UseShiftModalsDataProps {
  /**
   * Base path used for navigation when opening/closing facility modals.
   * This path is used to:
   * 1. Determine where to navigate when closing modals
   * 2. Support rendering the same modals from different root views (map/list)
   */
  baseNavigationPath: string;

  /**
   * Search mode used by underlying components to identify which mode was used to open shift modals.
   */
  searchMode: ShiftDiscoverySearchMode;
}

/**
 * Used to pre-populate shift modals with data so that the modals can be rendered immediately.
 * The data is refreshed by the useGetModalShiftData when the modals are opened.
 */
interface InitialShiftModalsData {
  shift?: ModalShiftData;
  bookabilityStatus?: BookabilityStatusItem;
  blockingRequirements?: WorkerRequirement[];
  resolvedShiftQualification?: string;
  hasHoursRestrictionConflict?: boolean;
  shiftSlots?: ShiftSlotCoalesced;
  friendsMap?: Map<string, WorkerPublicProfile>;
}

const InitialShiftModalsDataValue: InitialShiftModalsData = {
  shift: undefined,
  bookabilityStatus: undefined,
  hasHoursRestrictionConflict: undefined,
  blockingRequirements: undefined,
};

/**
 * This context manages the state for shift modals with these key features:
 * 1. Path generation - Handles both direct and nested modal paths
 * 2. Base path override - Supports rendering on top of workplace modals
 *
 * Modal Hierarchy:
 * - Shift modals can appear independently or on top of workplace modals
 * - When appearing on top of workplace modals, baseNavigationPathOverride is used
 * - This allows the workplace modal to stay mounted while shift modals animate in/out
 *
 * Usage:
 * - Direct modal: uses baseNavigationPath
 * - Nested modal: uses baseNavigationPath + baseNavigationPathOverride
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
function useShiftModalsData(props: UseShiftModalsDataProps) {
  const { baseNavigationPath, searchMode } = props;

  /**
   * When shift modals need to open on top of an existing modal (e.g. workplace open shifts modal),
   * we override the base navigation path. This ensures the new modal is rendered in addition to
   * the currently visible base modal, rather than replacing it.
   */
  const [baseNavigationPathOverride, setBaseNavigationPathOverride] = useState<string>();

  const history = useHistory();

  const navigateToModal = <S extends string>(
    path: S,
    params?: Parameters<typeof generatePath<S>>[1],
    forceReplacePath?: boolean
  ) => {
    const modalPath = generatePath(
      `${baseNavigationPath}${
        baseNavigationPathOverride ? `/${baseNavigationPathOverride}` : ""
      }/${path}`,
      params as unknown as Parameters<typeof generatePath<`${string}/${S}`>>[1]
    );

    if (isDefined(baseNavigationPathOverride) || forceReplacePath) {
      history.replace(modalPath);
    } else {
      history.push(modalPath);
    }
  };

  const { pathname } = useLocation();

  const isNestedModalContext = isDefined(baseNavigationPathOverride);
  const modalRouteParams = getShiftModalRouteParams(
    isNestedModalContext
      ? `${baseNavigationPath}/${baseNavigationPathOverride}`
      : baseNavigationPath,
    pathname
  );

  const shiftId = modalRouteParams?.shiftId;
  const onCallShiftId = modalRouteParams?.onCallShiftId;

  const [initialShiftModalsData, setInitialShiftModalsData] = useState<InitialShiftModalsData>(
    InitialShiftModalsDataValue
  );

  const initialDataIsStale =
    isDefined(shiftId) &&
    isDefined(initialShiftModalsData.shift) &&
    initialShiftModalsData.shift.id !== shiftId;

  useEffect(() => {
    if (initialDataIsStale) {
      setInitialShiftModalsData(InitialShiftModalsDataValue);
    }
  }, [initialDataIsStale, setInitialShiftModalsData]);

  const { data, isLoading, shiftFailedLoading } = useGetModalShiftData({ shiftId, onCallShiftId });

  const shift = data?.shift ?? initialShiftModalsData?.shift;
  const bookabilityStatus = data?.bookabilityStatus ?? initialShiftModalsData?.bookabilityStatus;
  const resolvedShiftQualification = data?.resolvedShiftQualification;
  const shiftSlots = data?.shiftSlots ?? initialShiftModalsData?.shiftSlots;
  const friendsMap = data?.friendsMap ?? initialShiftModalsData?.friendsMap;
  const hasHoursRestrictionConflict =
    data?.hasHoursRestrictionConflict ?? initialShiftModalsData?.hasHoursRestrictionConflict;

  // temporarily save this list as undefined if its not fetched yet, later we can just use the helper method to return []
  const blockingRequirements = isDefined(data?.workerRequirementsForShift)
    ? getBlockingWorkerRequirements(data?.workerRequirementsForShift)
    : initialShiftModalsData.blockingRequirements;

  const hasBlockingRequirements = hasBlockingWorkerRequirements(blockingRequirements);

  return {
    baseNavigationPath,
    baseNavigationPathOverride,
    blockingRequirements,
    bookabilityStatus,
    hasBlockingRequirements,
    hasHoursRestrictionConflict,
    hasMarkedInterest: shift?.attributes.isInterested,
    isLoadingShiftModalsData: isLoading,
    modalRouteParams,
    navigateToModal,
    resolvedShiftQualification,
    searchMode,
    setBaseNavigationPathOverride,
    setInitialShiftModalsData,
    shift,
    shiftFailedLoading,
    shiftId,
    shiftSlots,
    friendsMap,
  };
}

export const [ShiftModalsDataProvider, useShiftModalsDataContext] = constate(useShiftModalsData);
