import { isDefined } from "@clipboard-health/util-ts";
import { getDocumentCheckQualificationFromMultipleQualifications } from "@src/appV2/Accounts/Documents/helpers";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { minutesToMilliseconds } from "date-fns";

import { useGetBookabilityStatusForShifts } from "../../OpenShifts/ShiftAction/api/useGetBookabilityStatusForShifts";
import { useGetRequirementsForShift } from "../Documents/api/useGetRequirementsForShift";
import { useHasHourRestrictionConflict } from "../Shift/HourRestrictionConflicts/hooks/useHasHourRestrictionConflict";
import { useGetShiftModal } from "../Shift/Open/useGetShiftModal";
import { useGetShiftSlotsV2 } from "../Shift/Open/useGetShiftsSlotV2";
import { useGetFriendsList } from "../WorkWithFriends/hooks/useGetFriendsList";
import { useShiftDiscoveryUserFiltersContext } from "./Filters/useUserFiltersContext";

interface UseGetModalShiftDataProps {
  shiftId?: string;
  onCallShiftId?: string;
}

/**
 * Encapsulates data fetching for shift modals.
 *
 * Fetches shift details and bookability status for a shift, needed to open a shift modal.
 * Also provides missing requirements and hours restriction conflict data.
 */
export function useGetModalShiftData(props: UseGetModalShiftDataProps) {
  const { shiftId, onCallShiftId } = props;

  const { userId: workerId } = useDefinedWorker();

  const { licenses: selectedLicensesFilter = [], availableLicenses } =
    useShiftDiscoveryUserFiltersContext();

  const {
    data: { shift },
    isLoading: shiftIsLoading,
    isError: shiftIsError,
    offerIsLoading,
    offerIsError,
  } = useGetShiftModal(shiftId, onCallShiftId);

  const { workplace } = shift ?? {};

  const { shiftBookabilityById, queryResult: bookabilityStatusQueryResult } =
    useGetBookabilityStatusForShifts(
      {
        shiftIds: [shiftId ?? ""],
        agentId: workerId,
        onCallShiftId: onCallShiftId ?? undefined,
        returnFirstUnmetCriteria: false,
      },
      {
        enabled: isDefined(shiftId),
        refetchInterval: minutesToMilliseconds(5),
        refetchIntervalInBackground: true,
        refetchOnMount: "always",
        refetchOnWindowFocus: "always",
        refetchOnReconnect: "always",
      }
    );

  const shiftSlots = useGetShiftSlotsV2({
    shiftIds: shiftId ? [shiftId] : [],
  });

  const { data: friendsList } = useGetFriendsList();
  const friendsMap = new Map(friendsList?.data.map((friend) => [friend.id, friend]));
  const { isLoading: bookabilityIsLoading, isError: bookabilityIsError } =
    bookabilityStatusQueryResult;

  const bookabilityStatus = isDefined(shiftId) ? shiftBookabilityById[shiftId] : undefined;

  const resolvedShiftQualification = getDocumentCheckQualificationFromMultipleQualifications({
    shiftQualificationRequirement: shift?.attributes.qualification,
    selectedWorkerQualifications:
      selectedLicensesFilter.length > 0 ? selectedLicensesFilter : availableLicenses,
  });

  const { data: workerRequirementsForShift, isLoading: isLoadingWorkerRequirementsForShift } =
    useGetRequirementsForShift(
      {
        shift,
        qualification: resolvedShiftQualification,
      },
      {
        staleTime: 0, // We want to fetch the latest worker requirements for the shift on every open
      }
    );

  const { hasHoursRestrictionConflict, isLoading: hasHoursRestrictionConflictIsLoading } =
    useHasHourRestrictionConflict({
      shift: isDefined(shift)
        ? {
            id: shift.id,
            start: shift.attributes.start,
            end: shift.attributes.end,
          }
        : undefined,
      workplace: isDefined(workplace)
        ? {
            id: workplace.id,
            timezone: workplace.attributes.location.timezone,
            preventDoubleShifts: workplace.attributes.preventDoubleShifts,
            maxAllowedWorkHoursPerWeek: workplace.attributes.maxAllowedWorkHoursPerWeek,
            maxAllowedWorkConsecutiveHours: workplace.attributes.maxAllowedWorkConsecutiveHours,
          }
        : undefined,
    });

  const isLoading =
    isDefined(shiftId) &&
    (shiftIsLoading ||
      bookabilityIsLoading ||
      offerIsLoading ||
      isLoadingWorkerRequirementsForShift ||
      hasHoursRestrictionConflictIsLoading);

  return {
    data: {
      shift,
      resolvedShiftQualification,
      bookabilityStatus,
      workerRequirementsForShift,
      hasHoursRestrictionConflict,
      shiftSlots,
      friendsMap,
    },
    isLoading,
    shiftFailedLoading: shiftIsError || bookabilityIsError || offerIsError,
  };
}
