import { DotSeparatedList } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { ButtonBase, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import type { GeoLocation } from "@src/appV2/Location";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { FacilityAvatar } from "../../components/FacilityAvatar";
import { useDistanceToWorkplace } from "../../Workplace/useDistanceToWorkplace";
import type { MapViewWorkplace } from "../MapView/types";
import { SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH } from "../paths";
import { useWorkplaceModalsDataContext } from "../useWorkplaceModalsDataContext";

interface WorkplaceListItemProps {
  index: number;
  workplace: MapViewWorkplace;
  geoLocationFilter: GeoLocation;
}

export function WorkplaceListItem(props: WorkplaceListItemProps) {
  const { index, workplace, geoLocationFilter } = props;
  const { id: workplaceId, attributes, shiftsCount } = workplace;
  const { name, location: workplaceGeoLocation } = attributes;

  const history = useHistory();
  const { generateModalPath } = useWorkplaceModalsDataContext();

  const { formattedDistance } = useDistanceToWorkplace({
    workplaceGeoLocation,
    workerGeoLocation: geoLocationFilter,
  });

  return (
    <ButtonBase
      sx={(theme) => ({
        borderRadius: 0,
        width: "100%",
        justifyContent: "flex-start",
        ...(index !== 0 && {
          paddingTop: 4,
          borderTop: `1px solid ${String(theme.palette.divider)}`,
        }),
      })}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.WORKPLACE_OPEN_SHIFTS_OPENED, {
          workplaceId,
          trigger: "Browse All Workplaces",
        });

        history.push(
          generateModalPath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
            workplaceId,
          })
        );
      }}
    >
      <Stack direction="row" spacing={3} alignItems="center" pb={4}>
        <FacilityAvatar facilityId={workplaceId} size="small" />
        <Stack alignItems="flex-start">
          <Text semibold variant="h6" align="left">
            {name}
          </Text>
          <DotSeparatedList inline={false}>
            <Text semibold variant="caption" color={(theme) => theme.palette.text.secondary}>
              {shiftsCount} open {pluralize("shift", shiftsCount)}
            </Text>

            <Text variant="caption" color={(theme) => theme.palette.text.secondary}>
              {formattedDistance ? `${formattedDistance} away` : undefined}
            </Text>
          </DotSeparatedList>
        </Stack>
      </Stack>
    </ButtonBase>
  );
}
